import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import { SnackbarProvider } from 'notistack';
import { Analytics } from '@vercel/analytics/react';

import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

import '../public/assets/fonts/Baiser/stylesheet.css';
import '../public/assets/fonts/Circular/stylesheet.css';
import 'react-datepicker/dist/react-datepicker.css';

import { AuthProvider } from '../contexts/auth';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    & * {font-family: "Quicksand";}
  }
`;

const Wrapper = styled.div`
  & textarea:focus,
  input:focus,
  textarea:hover,
  input:hover {
    outline: none;
    border-color: ${({ theme }) => theme.colors.grey};
  }
`;

const theme = {
  colors: {
    green: '#01656A',
    lightGreen: 'rgba(1, 101, 106, .5)',
    grey: '#d5d7df',
    lightGrey: '#DDE0ED',
    textGrey: '#a0a5ba',
    white: '#fff',
    red: '#A41D55',
    redError: '#FE7979',
    darkBlue: '#24243A',
    purple: '#6A35FF',
  },
  palette: {},
};

// useful for new design
import '../css/main.css';
import FAQWidget from '../components/FAQWidget';

export default function App({ Component, pageProps }) {
  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <Wrapper>
            <SnackbarProvider maxSnack={3}>
              <Component {...pageProps} />
            </SnackbarProvider>
          </Wrapper>
        </AuthProvider>
      </ThemeProvider>
      <Analytics />
      <FAQWidget />
    </>
  );
}
