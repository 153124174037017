export const getUserRole = (user) => {
  if (!user || !user.roles) {
    return null;
  }
  // For now, do not handle multiple roles
  return user.roles[0];
};

export const getTitleString = (title: number) => {
  return title === 0 ? 'Mme' : title === 1 ? 'Mlle' : 'M.';
};

export const userRoles = {
  OWNER: 'OWNER',
  USER: 'USER',
  TENANT: 'TENANT',
  BUSINESS_PROVIDER: 'BUSINESS_PROVIDER',
  B2B2CPARTNER: 'B2B2CPARTNER',
};
