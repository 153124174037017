export enum ACCOMMODATION_STATUS {
  ONBOARDING_COMPLETE = 1,
  MANDATE_SENT_TO_USER = 2,
  MANDATE_TO_BE_SIGNED_BY_OWWNER = 3,
  MANDATE_SIGNED_BY_OWWNER = 4,
  DIAGNOSTICS_AND_PHOTOS_RECEIVED = 5,
  AD_PUBLISHED = 6,
  TENANT_FOUND = 7,
  TENANT_ACCEPTED = 8,
  FULLY_CONFIGURED = 9,
  PERMANENTLY_ARCHIVED = 99,
}

class VilogiInvoice {
  du: string;
  recu: string;
}

export const computeGlobalBalance = (invoices: VilogiInvoice[]) => {
  if (!invoices) {
    return 0;
  }
  const res = invoices.reduce((acc, invoice) => {
    if (invoice.du !== '') {
      return acc + Number.parseFloat(invoice.du);
    } else {
      if (invoice.recu != '') {
        return acc - Number.parseFloat(invoice.recu.trim());
      } else {
        return acc;
      }
    }
  }, 0);
  return Math.round(res * 100) / 100;
};

export const isNotPermanentlyArchivedAccommodation = (acc) => {
  return acc.status !== ACCOMMODATION_STATUS.PERMANENTLY_ARCHIVED;
};
