import { createTheme, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { COLOR_VERTGRAPHIQUES } from '../components/Analysis/colors';

const theme = createTheme({
  typography: {
    fontFamily: ['Quicksand'].join(','),
    fontSize: 14,
    subtitle1: {
      fontFamily: ['Quicksand'].join(','),
      fontWeight: 700,
      fontSize: 16,
    },
    subtitle2: {
      fontWeight: 700,
    },
    button: {
      textTransform: 'none',
      fontWeight: '500',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: '#00676B',
    },
    secondary: {
      main: '#F26440',
    },
    success: {
      main: COLOR_VERTGRAPHIQUES,
    },
    warning: {
      main: '#F26440',
    },
  },
  shape: {
    borderRadius: 10,
  },
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4,0,0.2,1)',
      easeOut: 'cubic-bezier(0.0,0,0.2,1)',
      easeIn: 'cubic-bezier(0.4,0,1,1)',
      sharp: 'cubic-bezier(0.4,0,0.6,1)',
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  zIndex: {
    mobileStepper: 1000,
    fab: 1050,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
});

const defaultTheme = createTheme(theme, {});

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      suffix="€"
    />
  );
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export { defaultTheme, NumericFormatCustom, Transition };
