/* eslint-disable @typescript-eslint/ban-types */
import React, { useState, useContext, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';

import api from '../api';

import { User } from '../models/user';
import { userRoles } from '../utils/userHelper';
import { ACCOMMODATION_STATUS } from '../utils/accommodationHelpers';

interface AuthContextInterface {
  isAuthenticated: boolean;
  user: User;
  setUser: Function;
  loading: boolean;
  logout: Function;
  getPersistentToken: Function;
  changePassword: Function;
}

const AuthContext = React.createContext<AuthContextInterface | null>(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const router = useRouter();

  useEffect(() => {
    async function loadUserFromCookies() {
      const token = Cookies.get('token');
      setLoading(true);
      if (token) {
        try {
          api.defaults.headers.Authorization = `Bearer ${token}`;
          const { data } = await api.get('/user/profile');
          setUser(data.body);
          if (router.pathname === '/magic') {
            router.push('/');
          }
        } catch (e) {
          logout();
        }
      }
      setLoading(false);
    }
    loadUserFromCookies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPersistentToken = async (prevToken) => {
    const res = await api.get(`/auth/new`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${prevToken}`,
      },
    });
    if (res.data.status === 400) {
      //invalid token, display error
      return router.push('/magic-link-error');
    }
    if (res && res.data && res.data.body && res.data.body.token) {
      const { data } = res;
      api.defaults.headers.Authorization = `Bearer ${data.body.token}`;
      const resUser = await api.get('/user/profile');
      const dataUser = resUser.data;
      setUser(dataUser.body);
      Cookies.set('token', data.body.token, { expires: 7 });
      if (dataUser.body.roles.includes(userRoles.B2B2CPARTNER)) {
        return router.push(`/partner/synthesis`);
      }
      if (dataUser.body.roles.includes(userRoles.BUSINESS_PROVIDER)) {
        return router.push(`/businessprovider/`);
      }
      if (dataUser.body.accommodationsOwner && dataUser.body.accommodationsOwner.length > 0) {
        const firstNotArchivedAccommodation = dataUser.body.accommodationsOwner.find((accommodation) => {
          return !accommodation.isArchived && accommodation.status !== ACCOMMODATION_STATUS.PERMANENTLY_ARCHIVED;
        });
        if (firstNotArchivedAccommodation?.status < ACCOMMODATION_STATUS.DIAGNOSTICS_AND_PHOTOS_RECEIVED) {
          return router.push(`/owner/accommodation/${dataUser.body.accommodationsOwner[0].id}/rental`);
        }
        return router.push(`/owner/synthesis`);
      }
      if (dataUser.body.accommodationsTenant && dataUser.body.accommodationsTenant.length > 0) {
        return router.push(`/tenant/accommodation/${dataUser.body.accommodationsTenant[0].id}`);
      }
      router.push('/');
      return data;
    }
  };

  const changePassword = async (prevToken) => {
    const res = await api.get(`/auth/new`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${prevToken}`,
      },
    });
    if (res.data.status === 400) {
      //invalid token, display error
      return router.push('/change-password-error');
    }
    if (res && res.data && res.data.body && res.data.body.token) {
      const { data } = res;
      api.defaults.headers.Authorization = `Bearer ${data.body.token}`;
      const resUser = await api.get('/user/profile');
      const dataUser = resUser.data;
      setUser(dataUser.body);
      Cookies.set('token', data.body.token, { expires: 7 });
      return router.push('/change-password-page');
    }
  };

  const logout = () => {
    Cookies.remove('token');
    delete api.defaults.headers.Authorization;
    return router.push('/login');
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!user,
        user,
        setUser,
        loading,
        logout,
        getPersistentToken,
        changePassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const ProtectRoute = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();
  const router = useRouter();
  if (!isAuthenticated) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      router.push('/login');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <div></div>;
  } else if (loading) {
    return <div>Loading</div>;
  } else if (isAuthenticated) {
    return children;
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      router.push('/login');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <div></div>;
  }
};

export const useAuth = () => useContext(AuthContext);
