import { Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { defaultTheme } from '../../utils/material-ui';
import { HelpOutlineRounded } from '@mui/icons-material';
import { SlideTransition } from '../../utils/ui-helpers';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

const FAQModal = ({ onClose, open }) => {
  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={SlideTransition} fullWidth sx={{ width: '100%' }} scroll='body'>
      <DialogTitle>
        <Stack direction="row">
          <Typography sx={{ flex: 1 }} variant="h6" component="div">
            {`Aide`}
          </Typography>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{padding: '24px 0'}}>
        <iframe src="https://owwner.freshdesk.com/support/solutions" width={'100%'} height={'600px'} style={{ border: 0 }} />
      </DialogContent>
    </Dialog>
  );
};

const FAQWidget = () => {
  const [displayModal, setDisplayModal] = useState(false);

  return (
    <>
      <Button
        sx={{
          backgroundColor: defaultTheme.palette.primary.main,
          color: '#FFF',
          position: 'fixed',
          bottom: '48px',
          right: '24px',
          borderRadius: '15px 5px 15px 15px',
          textTransform: 'none',
          ':hover': {
            backgroundColor: defaultTheme.palette.primary.main,
            transform: 'scale(1.02)',
          },
        }}
        onClick={() => {
          setDisplayModal(true);
        }}
      >
        <HelpOutlineRounded fontSize="small" sx={{ marginRight: '4px' }} />
        Aide
      </Button>
      <FAQModal
        onClose={() => {
          setDisplayModal(false);
        }}
        open={displayModal}
      />
    </>
  );
};

export default FAQWidget;
