import Axios from 'axios';
import getConfig from 'next/config';
const { API_URL } = getConfig().publicRuntimeConfig;

const api = Axios.create({
  baseURL: API_URL,
  // headers: {
  //     'Accept': 'application/json',
  //     'Content-Type': 'application/json'
  // }
});

export default api;
